<template>
  <div>
    <v-btn
      ref='csv'
      @click="createCsv"
    >
      Download Sample CSV
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'SampleCsv',
    data() {
      return {
        sampleCsv: [
          ['First Name','Last Name','Address','City','State','Zip','Phone','Email'],
          ['John','Doe','111 Fleet St','Springfield','MO','65738','4175551212','john@doe.com']
        ]
      }
    },
    methods: {
      createCsv() {
        let uri = 'data:text/csv;base64,Rmlyc3QgTmFtZSxMYXN0IE5hbWUsQWRkcmVzcyxDaXR5LFN0YXRlLFppcCxQaG9uZSxFbWFpbCxOdW1iZXIgb2YgU2VydmljZXMsUGVyY2VudCBEaXNjb3VudCxSZWd1bGFyIFByaWNlDQpKb2huLERvZSwxMTEgVGhlIFN0cmVldCBBdmUsU3ByaW5nZmllbGQsTU8sNjU4MDcsNDE3NTU1MTIxMixqb2huQGRvZS5jb20sMTIsMTAsMjU='
        
        let downloadLink = document.createElement("a")
        downloadLink.href = uri
        downloadLink.download = "data.csv"

        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      },
    }
  }
</script>

<style scoped>

</style>