<template>
  <v-container
    style="
      padding: 0;
      margin-top: 122px;
    "
    fluid
  >
    <status-bar :showCompletedButton="showCompletedButton"></status-bar>

    <v-container>
      <v-row>
        <v-card>
          <v-container class="d-flex">
            <v-col class="px-5 pt-7" cols="6">
                Customers can be added individually or in bulk via csv below.  
                <br />Need a hand creating a bulk list? Download the sample and fill in your data.
            </v-col>
            <v-col @click="toHome()" class="text-right pr-5" cols="6">
              <v-btn class="ma-5">Completed</v-btn>
            </v-col>
          </v-container>

          <v-card-text>
            <v-col cols="12">
              <customer-list @updateShowCompletedButton="updateCompletedButton" :key="key"></customer-list>
            </v-col>
            <v-col cols="12">
              <sample-csv></sample-csv>
            </v-col>
            <v-col cols="12">
              <upload-csv @updateShowCompletedButton="updateCompletedButton" :key="key"></upload-csv>
            </v-col>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>

    <Footer></Footer>

  </v-container>
</template>

<script>
// import _ from "lodash"

import { DB } from '../firebase/db'
import { mapState } from 'vuex'
import router from '../router'

import UploadCsv from '@/components/UploadCsv.vue'
import CustomerList from '@/components/CustomerList.vue'
import SampleCsv from '@/components/SampleCsv.vue';
import StatusBar from '@/components/StatusBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'UploadCustomers',
  data() {
    return {
      showCompletedButton: false
    }
  },
  computed: {
    ...mapState(['key', 'user']),
  },
  methods: {
    updateCompletedButton() {
      this.showCompletedButton = true
    },
    getSLPData() {
      // Get SLP Data here
      let customerAccountRef = DB.collection("user").doc(this.user.uid)

      customerAccountRef
        .get()
        .then((user) => {
          user = user.data()
          if(user.slpAgreement != undefined > 0 && user.customerList.length > 0) {
            this.showCompletedButton = true
          }
        }).catch((err) => {
          console.log(err)
        })
    },
    toHome() {
      if(this.$route.name !== 'Home') {
        router.push({ name: 'Home' })
      }
    }
  },
  components: {
    UploadCsv,
    CustomerList,
    SampleCsv,
    StatusBar,
    Footer,
  },
  mounted() {
    this.getSLPData()
  }
}
</script>

<style scoped>

</style>