<template>
  <div>
    <v-card>
      <v-card-text>
        <v-checkbox 
          v-model="hasHeaders"
          label="CSV has Headers"
        ></v-checkbox>
        <v-file-input
          v-model="file"
          show-size
        ></v-file-input>
        
        <v-col>
          <p>
            Deadline for customer(s) to apply.
          </p>
          <v-select
            v-model="selectedDate"
            :items="deadlines"
            item-text="title"
            label="Deadline"
          ></v-select>
        </v-col>
        
        <v-btn
          @click="load"
          v-if="file !== undefined && selectedDate"
        >
          Load
        </v-btn>

        <v-simple-table v-if="csvLoaded">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Field
                </th>
                <th class="text-left">
                  CSV Column
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>First Name</td>
                <v-select
                  v-model="firstNameColumn"
                  :items="displayColumnHeaders"
                ></v-select>
              </tr>
              <tr>
                <td>Last Name</td>
                <v-select
                  v-model="lastNameColumn"
                  :items="displayColumnHeaders"
                ></v-select>
              </tr>
              <tr>
                <td>Address</td>
                <v-select
                  v-model="addressColumn"
                  :items="displayColumnHeaders"
                ></v-select>
              </tr>
              <tr>
                <td>City</td>
                <v-select
                  v-model="cityColumn"
                  :items="displayColumnHeaders"
                ></v-select>
              </tr>
              <tr>
                <td>State</td>
                <v-select
                  v-model="stateColumn"
                  :items="displayColumnHeaders"
                ></v-select>
              </tr>
              <tr>
                <td>Zip</td>
                <v-select
                  v-model="zipColumn"
                  :items="displayColumnHeaders"
                ></v-select>
              </tr>
              <tr>
                <td>Phone</td>
                <v-select
                  v-model="phoneColumn"
                  :items="displayColumnHeaders"
                ></v-select>
              </tr>
              <tr>
                <td>Email</td>
                <v-select
                  v-model="emailColumn"
                  :items="displayColumnHeaders"
                ></v-select>
              </tr>
              <tr>
                <td>Number of Services</td>
                <v-select
                  v-model="numberOfServicesColumn"
                  :items="displayColumnHeaders"
                ></v-select>
              </tr>
              <tr>
                <td>Percent Discount</td>
                <v-select
                  v-model="percentDiscountColumn"
                  :items="displayColumnHeaders"
                ></v-select>
              </tr>
              <tr>
                <td>Regular Price</td>
                <v-select
                  v-model="regularPriceColumn"
                  :items="displayColumnHeaders"
                ></v-select>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <br />
        <br />
        <v-btn v-if="csvLoaded" @click="createCustomerList">Create Customer List</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash"
import Papa from "papaparse"

import { DB } from '../firebase/db'
import { mapState } from 'vuex'

export default {
  name: 'TenantLanding',
  data() {
    return {
      file: undefined,
      csv: [],
      csvLoaded: false,
      hasHeaders: false,
      customerList: [],

      firstNameColumn: null,
      lastNameColumn: null,
      addressColumn: null,
      cityColumn: null,
      stateColumn: null,
      zipColumn: null,
      phoneColumn: null,
      emailColumn: null,
      numberOfServicesColumn: null,
      percentDiscountColumn: null,
      regularPriceColumn: null,

      currentCustomerList: [],
      mergedCustomerList: [],
      selectedDate: undefined,
    }
  },
  computed: {
    ...mapState(['user']),
    displayColumnHeaders() {
      if (this.hasHeaders && this.csv.length) {
        // return this.csv[0]
        let arr = []
        for (let i = 0; i < this.csv[0].length; i++) {
          arr.push(this.csv[0][i] + ': Sample Data: ' + this.csv[1][i])
        }
        return arr
      } else if (!this.hasHeaders && this.csv.length) {
        let arr = []
        for (let i = 0; i < this.csv[0].length; i++) {
          let j = i + 1
          arr.push('Column ' + j + ': Sample Data: ' + this.csv[1][i])
        }
        return arr
      } else {
        return []
      }
    },
    levelPayMonthlyCost() {
      return (Math.round(((this.editedItem.regularPrice * this.editedItem.numberOfServices)/12 - ((this.editedItem.percentDiscount * .01) * this.editedItem.regularPrice * this.editedItem.numberOfServices / 12)) * 100)/100).toFixed(2)
    },
    deadlines() {
      var twoWeeks = new Date();
      var fourWeeks = new Date();
      var sixWeeks = new Date();
      twoWeeks = new Date(twoWeeks.setDate(twoWeeks.getDate() + 14))
      fourWeeks = new Date(fourWeeks.setDate(fourWeeks.getDate() + 28))
      sixWeeks = new Date(sixWeeks.setDate(sixWeeks.getDate() + 42))

      return ([{
          title: '2 Weeks from Today',
          value: twoWeeks.toDateString()
        },
        {
          title: '4 Weeks from Today',
          value: fourWeeks.toDateString()
        },
        {
          title: '6 Weeks from Today',
          value: sixWeeks.toDateString()
        }])
    }
  },
  methods: {
    load() {
      if (this.file) {
        let reader = new FileReader()
        reader.readAsText(this.file, "UTF-8")
        reader.onload = (evt) => {
          let csv = _.get(Papa.parse(evt.target.result, {skipEmptyLines: true}), "data")

          this.csv = csv

          this.sameHeadersAsSampleCsv = this.csv[0][0] === "First Name" && this.csv[0][1] === "Last Name" && this.csv[0][2] === "Address" && this.csv[0][3] === "City" && this.csv[0][4] === "State" && this.csv[0][5] === "Zip" && this.csv[0][6] === "Phone" && this.csv[0][7] === "Email" && this.csv[0][8] === "Number of Services" && this.csv[0][9] === "Percent Discount" && this.csv[0][10] === "Regular Price"

          if (this.sameHeadersAsSampleCsv) {
            this.createCustomerList()
            this.updateShowCompletedButton()
          } else {
            this.csvLoaded = true
            this.updateShowCompletedButton()
          }
        }
        reader.onerror = function () {
          alert('There was an error reading this file.')
        }
      }
    },

    updateShowCompletedButton() {
      this.$emit('updateShowCompletedButton')
    },

    isDuplicateCustomer(customer) {
      let isDuplicate = false
      _.forEach(this.currentCustomerList, (cus) => {
        if(customer.firstName === cus.firstName && customer.lastName === cus.lastName && customer.email === cus.email) {
          isDuplicate = true
        }
      })
      return isDuplicate
    },
    createCustomerList() {
      // Will add duplicate info to DB
      let firstNameColumnIndex
      let lastNameColumnIndex
      let addressColumnIndex
      let cityColumnIndex
      let stateColumnIndex
      let zipColumnIndex
      let phoneColumnIndex
      let emailColumnIndex
      let numberOfServicesColumnIndex
      let percentDiscountColumnIndex
      let regularPriceColumnIndex


      if (this.sameHeadersAsSampleCsv) {
        firstNameColumnIndex = 0 
        lastNameColumnIndex = 1
        addressColumnIndex = 2
        cityColumnIndex = 3
        stateColumnIndex = 4
        zipColumnIndex = 5
        phoneColumnIndex = 6
        emailColumnIndex = 7
        numberOfServicesColumnIndex = 8
        percentDiscountColumnIndex = 9
        regularPriceColumnIndex = 10
      } else {
        firstNameColumnIndex = _.findIndex(this.displayColumnHeaders, (colIndex) => {
          return colIndex === this.firstNameColumn
        })
        lastNameColumnIndex = _.findIndex(this.displayColumnHeaders, (colIndex) => {
          return colIndex === this.lastNameColumn
        })
        addressColumnIndex = _.findIndex(this.displayColumnHeaders, (colIndex) => {
          return colIndex === this.addressColumn
        })
        cityColumnIndex = _.findIndex(this.displayColumnHeaders, (colIndex) => {
          return colIndex === this.cityColumn
        })
        stateColumnIndex = _.findIndex(this.displayColumnHeaders, (colIndex) => {
          return colIndex === this.stateColumn
        })
        zipColumnIndex = _.findIndex(this.displayColumnHeaders, (colIndex) => {
          return colIndex === this.zipColumn
        })
        phoneColumnIndex = _.findIndex(this.displayColumnHeaders, (colIndex) => {
          return colIndex === this.phoneColumn
        })
        emailColumnIndex = _.findIndex(this.displayColumnHeaders, (colIndex) => {
          return colIndex === this.emailColumn
        })
        numberOfServicesColumnIndex = _.findIndex(this.displayColumnHeaders, (colIndex) => {
          return colIndex === this.numberOfServicesColumn
        })
        percentDiscountColumnIndex = _.findIndex(this.displayColumnHeaders, (colIndex) => {
          return colIndex === this.percentDiscountColumn
        })
        regularPriceColumnIndex = _.findIndex(this.displayColumnHeaders, (colIndex) => {
          return colIndex === this.regularPriceColumn
        })
      }

      if (this.hasHeaders && this.customerList.length === 0) {
        // remove first row
        for (let i = 1; i < this.csv.length; i++) {
          let customer = {
            firstName: this.csv[i][firstNameColumnIndex],
            lastName: this.csv[i][lastNameColumnIndex],
            address: this.csv[i][addressColumnIndex],
            city: this.csv[i][cityColumnIndex],
            state: this.csv[i][stateColumnIndex],
            zip: this.csv[i][zipColumnIndex],
            phone: this.validatePhone(this.csv[i][phoneColumnIndex]),
            email: this.csv[i][emailColumnIndex],
            numberOfServices: this.csv[i][numberOfServicesColumnIndex],
            percentDiscount: this.csv[i][percentDiscountColumnIndex],
            regularPrice: this.csv[i][regularPriceColumnIndex],
            levelPayMonthlyCost: (Math.round(((this.csv[i][regularPriceColumnIndex] * this.csv[i][numberOfServicesColumnIndex])/12 - ((this.csv[i][percentDiscountColumnIndex] * .01) * this.csv[i][regularPriceColumnIndex] * this.csv[i][numberOfServicesColumnIndex] / 12)) * 100)/100).toFixed(2),
            deadline: this.selectedDate,
            agreementSentDate: 'Not Sent'
          }
          let isDuplicate = this.isDuplicateCustomer(customer)

          if (!isDuplicate) {
            this.customerList.push(customer)
          }
        }

        this.mergedCustomerList = _.union(this.customerList, this.currentCustomerList)
        
        if (this.customerList.length > 0) {
          this.updateFirebase()
        } else {
          alert('Only duplicate customer information in CSV.')
          this.resetData()
        }

      } else if (this.customerList.length === 0) {
        for (let i = 0; i < this.csv.length; i++) {
          let customer = {
            firstName: this.csv[i][firstNameColumnIndex],
            lastName: this.csv[i][lastNameColumnIndex],
            address: this.csv[i][addressColumnIndex],
            city: this.csv[i][cityColumnIndex],
            state: this.csv[i][stateColumnIndex],
            zip: this.csv[i][zipColumnIndex],
            phone: this.validatePhone(this.csv[i][phoneColumnIndex]),
            email: this.csv[i][emailColumnIndex],
            numberOfServices: this.csv[i][numberOfServicesColumnIndex],
            percentDiscount: this.csv[i][percentDiscountColumnIndex],
            regularPrice: this.csv[i][regularPriceColumnIndex],
            levelPayMonthlyCost: (Math.round(((this.csv[i][regularPriceColumnIndex] * this.csv[i][numberOfServicesColumnIndex])/12 - ((this.csv[i][percentDiscountColumnIndex] * .01) * this.csv[i][regularPriceColumnIndex] * this.csv[i][numberOfServicesColumnIndex] / 12)) * 100)/100).toFixed(2),
            deadline: this.selectedDate,
            agreementSentDate: 'Not Sent'
          }
          let isDuplicate = this.isDuplicateCustomer(customer)

          if (!isDuplicate) {
            this.customerList.push(customer)
          }
        }

        this.mergedCustomerList = _.union(this.customerList, this.currentCustomerList)

        if (this.customerList.length > 0) {
          this.updateFirebase()
        } else {
          alert('Only duplicate customer information in CSV.')
          this.resetData()
        }

      } else {
        // Need to send current customerList first
        console.log('this.customerList: ', this.customerList)
      }
    },
    getCurrentCustomers(){
      let docRef = DB.collection('user').doc(this.user.uid)
        docRef.get()
          .then((user) => {
            user = user.data()
            this.currentCustomerList = user.customerList
          })
    },
    resetData() {
      this.file = undefined
      this.csv = []
      this.csvLoaded = false
      this.hasHeaders = false
      this.customerList = []
      this.nameColumn = null
      this.addressColumn = null
      this.phoneColumn = null
      this.emailColumn = null
      this.numberOfServicesColumn = null
      this.percentDiscountColumn = null
      this.regularPriceColumn = null
    },
    reloadCustomerComponent() {
      this.$store.dispatch('iterateKey')
    },
    updateFirebase() {
      let customerAccountRef = DB.collection("user").doc(this.user.uid)

      customerAccountRef
        .set({
          customerList: this.mergedCustomerList
        }, { merge: true })
        .then(() => {
          console.log('Customer list Saved to Firebase')
          this.resetData()
          this.reloadCustomerComponent()
          this.getCurrentCustomers()
          alert('Customer List Successfully uploaded.')
        })
        .catch((err) => {
          console.error('Error setting Document: ', err)
          this.resetData()
        })
    },
    validatePhone(number) {
      if (number.length === 10) {
        return '+1'+_.toString(number)
      } else if (number.length === 11){
        return '+'+_.toString(number)
      } else {
        return 'Invalid Phone'
      }
    }
  },
  components: {},
  mounted() {
    this.getCurrentCustomers()
  }
}
</script>

<style scoped>

</style>